import { Box } from "@mui/material"

const Home = () => {
  return(
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <h1>Chargeworks Admin Dashboard</h1>
    </Box>
  );
};

export default Home;