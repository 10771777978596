import React, { useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AuthProvider, AuthProtectedRoute } from './components/Auth';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import ResponsiveAppBar from './components/Navbar'

import { GarageLocationProvider, GarageLocationProtectedRoute } from './components/Location';
import { SmartCarNumberProvider } from './components/Smartcars';
import { ValidateAccess } from './components/Access';

import NoPage from "./pages/NoPage";
import Home from "./pages/Home";
import Smartcar from "./pages/Smartcar";
import Sotefin from "./pages/Sotefin";
import Reeta from "./pages/Reeta";
import Reports from "./pages/Reports";
import Media from "./pages/Media";

type PageComponentsType = {
  [key: string]: () => JSX.Element;
};

const PageComponents: PageComponentsType =  {
  Smartcar: Smartcar,
  Sotefin: Sotefin,
  Reeta: Reeta,
  Reports: Reports,
  Media: Media,
}

const pages = Object.keys(PageComponents);
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout']

const createSettings = (logout: () => void) => [
  {
    title: 'Profile',
    onClick: () => console.log('Profile')
  },
  {
    title: 'Account',
    onClick: () => console.log('Account')
  },
  {
    title: 'Dashboard',
    onClick: () => console.log('Dashboard')
  },
  {
    title: 'Logout',
    onClick: () => {
      console.log('logout called')
      localStorage.removeItem('NRBA_SESSION')
      logout()
    }
  }
];

export default function App() {
  const { logout } = useAuth0();
  const settings = useMemo(() => createSettings(logout), [logout]);
  
  return (
    <div className="App">
      <BrowserRouter>
        <ResponsiveAppBar
          pages={pages}
          settings={settings}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NoPage />} />
          {pages.map((page) => {
            const PageComponent = PageComponents[page]
            return (
              <Route
                key={`/${page.toLowerCase()}`}
                path={`/${page.toLowerCase()}`} // page routes named after the page strings, converted to lowercase
                element={
                  <AuthProtectedRoute element={
                    <ValidateAccess permission={`page:${page.toLowerCase()}`.replace('smartcar', 'smartcar_control')}>
                      <GarageLocationProtectedRoute element={<PageComponent />} />
                    </ValidateAccess>
                  } />
                }
              />
            )
            })};
        </Routes>
      </BrowserRouter>
    </div>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      useRefreshTokens={true}
      cacheLocation='localstorage'
      authorizationParams={{
        audience: 'https://parkworksus.com',
        redirect_uri: `${window.location.origin}`,
        scope: process.env.REACT_APP_AUTH0_SCOPE,
      }}
    >
      <AuthProvider>
        <GarageLocationProvider>
          <SmartCarNumberProvider>
              <App />
          </SmartCarNumberProvider>
        </GarageLocationProvider>
      </AuthProvider>
    </Auth0Provider>
  </React.StrictMode>
);