import React, { useState, useEffect } from 'react';

import {
  Card,
  Box,
  Typography,
  CardContent
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useGarageLocation } from '../components/Location';
import { useEndpoint } from '../hooks/endpoint.hook';
import { Endpoint } from '../util/endpoint.util';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

interface Report {
  summary: string;
  cars: {
      [key: string]: string;
  }
}

const Reports= () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const endpoint = useEndpoint();
  const [reports, setReports] = useState<Report | null>(null);
  const { garageLocation } = useGarageLocation()!;

  const defaultStartDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 1); // Set to the start of the previous month
  defaultStartDate.setDate(1); // Set the date to the first day of the month
  const defaultEndDate = new Date(defaultStartDate.getFullYear(), defaultStartDate.getMonth() + 1, 0); // Get the last day of the previous month
  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);

  const getReports = (endpoint: Endpoint, startDate: Date, endDate: Date, garageLocation: string) => {
    return endpoint?.get('report/api/reports', {
      params: {
        start_date: startDate.toISOString().split('T')[0],
        end_date: endDate.toISOString().split('T')[0],
        location: garageLocation,
      }
    })
      .then(response => response.data)
      .catch(console.error)
  };

  useEffect(() => {
    endpoint && startDate && endDate && garageLocation && endDate > startDate && getReports(endpoint, startDate, endDate, garageLocation).then(reports => setReports(reports as unknown as any));
  }, [endpoint, startDate, endDate, garageLocation]); 

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box p={2}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Typography variant="h4" align="center" gutterBottom pt={2}>
          Garage Reports
        </Typography>

        <Box display="flex" justifyContent="center" mb={2}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue: any) => setStartDate(newValue)}
            maxDate={endDate || undefined}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue: any) => setEndDate(newValue)}
            minDate={startDate || undefined}
          />
        </Box>

          {reports && reports['summary'] && (
            <Accordion key={reports['summary']}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">Car Report Summary</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Viewer
                  fileUrl={reports['summary']}
                  plugins={[
                    defaultLayoutPluginInstance,
                  ]}
                />
              </AccordionDetails>
            </Accordion>
          )}
        
          {reports && garageLocation && (
            <>
              {!!Object.keys(reports || []).length && Object.keys(reports['cars'] || []).map((report: any) => (
                  <Accordion key={report}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h6">Car {report} Report</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Viewer
                        fileUrl={reports['cars'][report]}
                        plugins={[
                          defaultLayoutPluginInstance,
                        ]}
                      />
                    </AccordionDetails>
                  </Accordion>
              ))}

              {endDate && startDate && endDate < startDate && (
                <Card>
                  <CardContent>
                    Invalid Date Range
                  </CardContent>
                </Card>
              )}

              {(!Object.keys(reports || []).length && (endDate && startDate && startDate > endDate)) && (
                <Card>
                  <CardContent>
                    No Reports Available
                  </CardContent>
                </Card>
              )}
            </>
          )}
        </Worker>
      </Box>
    </LocalizationProvider>
  )
};

export default Reports;